import {
  NewButtonType,
  NewButtonSize,
  Button as DesignSystemButton,
} from '@loveholidays/design-system';
import React from 'react';

import { ButtonProps as ButtonType } from '@AuroraTypes';

// TODO: Sanity currently has options for the old variants, it should be updated and current content migrated to the new variants then this can be removed.
const variantMap: Record<string, NewButtonType> = {
  Primary: 'Primary',
  Secondary: 'Outline',
  Subtle: 'Secondary',
};

const sizeMap: Record<string, NewButtonSize> = {
  Average: '36',
  Large: '36',
  Big: '48',
  Huge: '64',
};

export const Button: React.FC<ButtonType> = ({ label, url, variant, size, internal }) => (
  <DesignSystemButton
    variant={variantMap[variant]}
    size={sizeMap[size]}
    internal={internal}
    href={url}
  >
    {label}
  </DesignSystemButton>
);
